import React from 'react';
import { Helmet } from 'react-helmet';
import ComingSoonImage from '../assets/coming_soon.jpg';

import './index.css';

const Index = () => (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>3D Golf</title>
            <link rel="canonical" href="https://3dgolf.app" />
        </Helmet>

        <div className="flex flex-col items-center justify-center h-screen">
            <div className="flex flex-col items-center justify-center h-screen">
                <img src={ComingSoonImage} />
            </div>
        </div>
    </>
);

export default Index;
